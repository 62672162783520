import "./App.css";
import Header from "./Header";
import Herosection from "./Herosection";
import Model_popup from "./Modal_popup";
import New_website from "./New_website";

function App() {
  return (
    <div>
      <Header />
      {/* <Herosection /> */}
      <Model_popup />

      <New_website />
    </div>
  );
}

export default App;
