import React, { useEffect, useState } from "react";
const Project_gallery = () => {
  const [isOpenIndex, setIsOpenIndex] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Handle window resize
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    if (window.innerWidth >= 991) {
      setIsOpenIndex(null); // No item is open
    } else {
      setIsOpenIndex(0); // Open the first item on smaller screens
    }
  };

  // Handle hover to play video and mark the item as open
  const handleMouseEnter = (index) => {
    setIsOpenIndex(index);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Trigger the resize on initial load
    handleResize();

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className="container-fluid pt-5" id="gallery">
        <div className="row">
          <div className="col-md-12 text-center pt-5 pb-5">
            <h2>Project Gallery</h2>
          </div>
        </div>

        <div className="ojasvi pg-wrapper">
          <div className="pg-accordion">
            <ul>
              {[
                {
                  src: "../../images/Project_gallery/lifedesigned_homes.mp4",
                  label: "LifeDesigned® Homes",
                },
                {
                  src: "../../imagesProject_gallery/landscaped_green.mp4",
                  label: "Landscaped Greens",
                },
                {
                  src: "../../imagespodium_level_amenities.mp4",
                  label: "Podium Level Amenities",
                },
                {
                  src: "../../imagessky_club_amenities.mp4",
                  label: "Sky Club Amenities",
                },
              ].map((item, index) => (
                <li
                  key={index}
                  className={isOpenIndex === index ? "isOpen" : ""}
                  onMouseEnter={() => handleMouseEnter(index)}
                >
                  <div className="pg-video">
                    <video
                      playsInline
                      autoPlay={isOpenIndex === index}
                      muted
                      loop
                    >
                      <source src={item.src} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <p>{item.label}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project_gallery;
