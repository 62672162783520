import React from "react";

const About = () => {
  return (
    <div className="about">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2>
              A World LifeDesigned® To Elevate Your Life In More Ways Than One.
            </h2>

            <p>
              Unwind in a space where vibrance and liveliness are permanent
              inhabitants. Spend time in delight and indulge in holistic
              rejuvenation. The youthfulness and vitality of this destination
              make every moment a timeless memory.
              <br />
              <br />
              Presenting Birla Ojasvi at Rajarajeshwari Nagar – the landmark
              that transcends conventional living. Elevating the residential
              experience in RR Nagar, Bengaluru, the towers offer an exclusive
              panorama of lush green landscapes, a dynamic cityscape and the
              vibrant Sky Club. At Birla Ojasvi, nature, urban vibrancy, and
              leisure are seamlessly integrated into your everyday life.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
