import React, { useEffect } from "react";

export default function Model_popup() {
  useEffect(() => {
    // Use Bootstrap's modal API to show the modal on page load
    const modal = new window.bootstrap.Modal(
      document.getElementById("exampleModal")
    );
    modal.show();
  }, []);

  return (
    <div>
      <div
        class="modal fade text-left"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered  modal-lg "
          role="document"
        >
          <div class="modal-content p-3">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Want to know more about the project?
                <div className="new">
                  {" "}
                  Please leave your details below and we will get in touch with
                  you.
                </div>
              </h5>
              <h6></h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form
                name="contact"
                method="post"
                data-netlify="true"
                onSubmit="submit"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="contact" />
                <div class="row">
                  <div class="col">
                    <input
                      required
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      name="name"
                    />
                  </div>
                  <div class="col">
                    <input
                      required
                      type="text"
                      class="form-control"
                      placeholder="Phone Number"
                      name="phonenumber"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div class="col">
                    <input
                      required
                      type="text"
                      class="form-control"
                      placeholder="Email"
                      name="email"
                    />
                  </div>
                  <div class="col">
                    <fieldset>
                      <div>
                        <label>Need Brochure</label>
                        <br class="mobile-break"></br>
                        <input
                          type="radio"
                          class=" ml-0 ml-md-3 mr-1"
                          name="brochure"
                          value="Yes"
                          id="y"
                        />
                        <label htmlFor="y">Yes</label>
                        <input
                          type="radio"
                          class="radio ml-3 mr-1"
                          name="brochure"
                          value="No"
                          id="n"
                        />
                        <label htmlFor="no">No</label>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="row">
                  <div class="col">
                    <fieldset>
                      <div>
                        <label>Your Preference </label>
                        <br class="mobile-break"></br>
                        <input
                          type="radio"
                          class="ml-0 ml-md-3 mr-1"
                          name="bhk"
                          value="1BHK"
                          id="1BHK"
                        />
                        <label htmlFor="1BHK">1 BHK</label>
                        <input
                          type="radio"
                          class="radio ml-3 mr-1"
                          name="bhk"
                          value="2BHK"
                          id="2BHK"
                        />
                        <label htmlFor="2BHK">2 BHK</label>
                        <input
                          type="radio"
                          class="radio ml-3 mr-1"
                          name="bhk"
                          value="3BHK"
                          id="3BHK"
                        />
                        <label htmlFor="3BHK">3 BHK</label>
                        <input
                          type="radio"
                          class="radio ml-3 mr-1"
                          name="bhk"
                          value="Row villas"
                          id="Rowvillas"
                        />
                        <label htmlFor="Rowvillas">Row villas</label>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="row mt-3">
                  <div class="col">
                    <input
                      type="text"
                      required
                      class="form-control"
                      placeholder="Message"
                      name="message"
                    />
                  </div>
                </div>
                {/* 
                        <div className="row">
                          <div className="col-12">
                            <div
                              style={{ fontSize: 12 }}
                              className="text-center"
                            >
                              *Phase - 1 Sold Out,{" "}
                              <b>Hurry up for Phase - 2 (NEW LAUNCH)</b>
                            </div>
                          </div>
                        </div> */}

                <div className="row">
                  <div className="col-12 text-center">
                    <button type="submit" className="btn-be-secondary mt-4">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
