import React from "react";

export default function Header() {
  return (
    <div className="header mt-2">
      <nav class="navbar navbar-expand-lg bg-light py-3">
        <div class="container-fluid">
          <a class="navbar-brand" href="#">
            <img
              src="./images/birla-ojasvi-logo.png"
              className="img-fluid birla_estate"
              alt="Logo"
            />
          </a>

          <div
            class="collapse navbar-collapse justify-content-center d-none d-lg-flex"
            id="navbarNav"
          >
            <ul class="navbar-nav">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  aria-current="page"
                  href="#project_showcase"
                >
                  Showcase
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#highlights">
                  Highlights
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#amenities">
                  Amenities
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#gallery">
                  Gallery
                </a>
              </li>
            </ul>
          </div>

          <div class="d-flex justify-content-end align-items-center">
            <div>
              <a
                data-toggle="modal"
                data-target="#exampleModal"
                className="btn-be-secondary mr-3"
                href=""
              >
                Book A Site Visit
              </a>
            </div>

            <div class="d-none d-lg-block">
              <img
                src="./images/birla_aditya.jpg"
                className="img-fluid aditya_birla"
                alt="Aditya Birla"
              />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
