import React from "react";

const Project_amenities = () => {
  return (
    <div className="project_amenities" id="amenities">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center pb-4">
            <h2>Project Amenities</h2>
          </div>
        </div>

        <div class="">
          <div class="project_ameties owl-carousel owl-theme" id="slAminities">
            <div class="item">
              <div class="pa-list">
                <div class="pa-icon">
                  <img
                    src="../../images/Project_amenities/icon_Ojaswi_Barbeque-pods.png"
                    alt=""
                  />
                </div>
                <p>
                  Barbeque
                  <br />
                  Pods
                </p>
              </div>
              <div class="pa-list">
                <div class="pa-icon">
                  <img
                    src="../../images/Project_amenities/icon_Ojaswi_Party Lawn.png"
                    alt=""
                  />
                </div>
                <p>
                  Party
                  <br />
                  Lawn
                </p>
              </div>
            </div>
            <div class="item">
              <div class="pa-list">
                <div class="pa-icon">
                  <img
                    src="../../images/Project_amenities/icon_Ojaswi_Jacuzzi.png"
                    alt=""
                  />
                </div>
                <p>
                  Jacuzzi
                  <br />
                  &nbsp;
                </p>
              </div>
              <div class="pa-list">
                <div class="pa-icon">
                  <img
                    src="../../images/Project_amenities/icon_Ojaswi_Swimming pool.png"
                    alt=""
                  />
                </div>
                <p>
                  Swimming
                  <br />
                  Pool
                </p>
              </div>
            </div>
            <div class="item">
              <div class="pa-list">
                <div class="pa-icon">
                  <img
                    src="../../images/Project_amenities/icon_Ojaswi_Kids pool.png"
                    alt=""
                  />
                </div>
                <p>
                  Kids
                  <br />
                  Pool
                </p>
              </div>
              <div class="pa-list">
                <div class="pa-icon">
                  <img
                    src="../../images/Project_amenities/icon_Ojaswi_Pet park.png"
                    alt=""
                  />
                </div>
                <p>
                  Pet
                  <br />
                  Park
                </p>
              </div>
            </div>
            <div class="item">
              <div class="pa-list">
                <div class="pa-icon">
                  <img
                    src="../../images/Project_amenities/icon_Ojaswi_Lawn Tennis court.png"
                    alt=" /"
                  />
                </div>
                <p>
                  Lawn Tennis
                  <br />
                  Court
                </p>
              </div>
              <div class="pa-list">
                <div class="pa-icon">
                  <img
                    src="../../images/Project_amenities/icon_Ojaswi_Basket Ball Court.png"
                    alt=""
                  />
                </div>
                <p>
                  Basketball
                  <br />
                  Court
                </p>
              </div>
            </div>
            <div class="item">
              <div class="pa-list">
                <div class="pa-icon">
                  <img
                    src="../../images/Project_amenities/icon_Ojaswi_Amphitheatre.png"
                    alt=""
                  />
                </div>
                <p>
                  Amphitheatre
                  <br />
                  &nbsp;
                </p>
              </div>
              <div class="pa-list">
                <div class="pa-icon">
                  <img
                    src="../../images/Project_amenities/icon_Ojaswi_gym.png"
                    alt=""
                  />
                </div>
                <p>
                  Gym
                  <br />
                  &nbsp;
                </p>
              </div>
            </div>
            <div class="item">
              <div class="pa-list">
                <div class="pa-icon">
                  <img
                    src="../../images/Project_amenities/icon_Ojaswi_Yoga.png"
                    alt=""
                  />
                </div>
                <p>
                  Yoga Deck
                  <br />
                  &nbsp;
                </p>
              </div>
              <div class="pa-list">
                <div class="pa-icon">
                  <img
                    src="../../images/Project_amenities/icon_Ojaswi_Steam & Sauna.png"
                    alt=""
                  />
                </div>
                <p>
                  Steam &<br />
                  Sauna
                </p>
              </div>
            </div>
            <div class="item">
              <div class="pa-list">
                <div class="pa-icon">
                  <img
                    src="../../images/Project_amenities/icon_Ojaswi_Squash.png"
                    alt=""
                  />
                </div>
                <p>
                  Squash Court
                  <br />
                  &nbsp;
                </p>
              </div>
              <div class="pa-list">
                <div class="pa-icon">
                  <img
                    src="../../images/Project_amenities/icon_Ojaswi_Coffee Lounge.png"
                    alt=""
                  />
                </div>
                <p>
                  Coffee
                  <br />
                  Lounge
                </p>
              </div>
            </div>
            <div class="item">
              <div class="pa-list">
                <div class="pa-icon">
                  <img
                    src="../../images/Project_amenities/icon_Ojaswi_Media Lounge.png"
                    alt=""
                  />
                </div>
                <p>
                  Media
                  <br />
                  Lounge
                </p>
              </div>
              <div class="pa-list">
                <div class="pa-icon">
                  <img
                    src="../../images/Project_amenities/icon_Ojaswi_Billiards.png"
                    alt=""
                  />
                </div>
                <p>
                  Billiards
                  <br />
                  &nbsp;
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="cta-db">
          <a
            href="brochure.pdf"
            class="btn-be-secondary ico-left"
            data-toggle="modal"
            data-target="#exampleModal"
          >
            <i class="fa-solid fa-download"></i>Download Brochure
          </a>
        </div>
      </div>
    </div>
  );
};

export default Project_amenities;
