import React from "react";
import Hero_slider from "./Hero_slider";
import About from "./About";
import Project_showcase from "./Project_showcase";
import Project_Highlights from "./Project_Highlights";
import Project_amenities from "./Project_amenities";
import Project_gallery from "./Project_gallery";
import Faq from "./Faq";
import Footer from "./Footer";
import { Icon } from "@iconify/react";

const New_website = () => {
  return (
    <div>
      {" "}
      <a
        href="https://api.whatsapp.com/send?phone=+916361156201&text=hello"
        class="float"
        target="_blank"
      >
        <Icon class="my-float" icon="ic:baseline-whatsapp" />
      </a>
      <Hero_slider />
      <About />
      <Project_showcase />
      <Project_Highlights />
      <Project_amenities />
      <Project_gallery />
      <Faq />
      <Footer />
    </div>
  );
};

export default New_website;
