import React from "react";

const Hero_slider = () => {
  return (
    <div>
      <div class="owl-carousel owl-theme hero_carosel">
        <div class="item">
          <picture>
            <source
              media="(max-width: 640px)"
              srcset="../../images/Hero_images/birla-ojasvi-banner-01-sm.jpg"
            />
            <img
              src="../../images/Hero_images/birla-ojasvi-banner-01.jpg"
              alt="Banner 1"
              class="img-fluid"
            />
          </picture>
          <div class="desc">
            <p class="carsl_cnt">
              A World LifeDesigned® To Elevate Your Life In More Ways Than One.
            </p>
          </div>
        </div>
        <div class="item">
          <picture>
            <source
              media="(max-width: 640px)"
              srcset="../../images/Hero_images/birla-ojasvi-banner-02-sm.jpg"
            />
            <img
              src="../../images/Hero_images/birla-ojasvi-banner-02.jpg"
              alt="Banner 2"
              class="img-fluid"
            />
          </picture>
          <div class="desc">
            <p class="carsl_cnt">
              A World LifeDesigned® To Elevate Your Life In More Ways Than One.
            </p>
          </div>
        </div>
        <div class="item">
          <picture>
            <source
              media="(max-width: 640px)"
              srcset="../../images/Hero_images/birla-ojasvi-banner-03-sm.jpg"
            />
            <img
              src="../../images/Hero_images/birla-ojasvi-banner-03.jpg"
              alt="Banner 3"
              class="img-fluid"
            />
          </picture>
          <div class="desc">
            <p class="carsl_cnt">
              A World LifeDesigned® To Elevate Your Life In More Ways Than One.
            </p>
          </div>
        </div>
        <div class="item">
          <picture>
            <source
              media="(max-width: 640px)"
              srcset="../../images/Hero_images/birla-ojasvi-banner-04-sm.jpg"
            />
            <img
              src="../../images/Hero_images/birla-ojasvi-banner-04.jpg"
              alt="Banner 4"
              class="img-fluid"
            />
          </picture>
          <div class="desc">
            <p class="carsl_cnt">
              A World LifeDesigned® To Elevate Your Life In More Ways Than One.
            </p>
          </div>
        </div>
        <div class="item">
          <picture>
            <source
              media="(max-width: 640px)"
              srcset="../../images/Hero_images/birla-ojasvi-banner-05-sm.jpg"
            />
            <img
              src="../../images/Hero_images/birla-ojasvi-banner-05.jpg"
              alt="Banner 5"
              class="img-fluid"
            />
          </picture>
          <div class="desc">
            <p class="carsl_cnt">
              A World LifeDesigned® To Elevate Your Life In More Ways Than One.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero_slider;
