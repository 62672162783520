import React from "react";

const Project_showcase = () => {
  return (
    <div className="project" id="project_showcase">
      <div className="">
        <h2 className="text-center p-5">Project Showcase</h2>

        <div class="hero_carosel owl-carousel owl-theme">
          <div class="item">
            <div class="hero-img">
              <img
                src="../../images/Project_showcase/ojasvi-project-showcase-01.jpg"
                alt=" /"
              />
            </div>
            <div class="desc ">
              <p class="carsl_cnt">Market Square</p>
            </div>
          </div>
          <div class="item">
            <div class="hero-img">
              <img
                src="../../images/Project_showcase/ojasvi-project-showcase-02.jpg"
                alt=""
              />
            </div>
            <div class="desc ">
              <p class="carsl_cnt">Teakwood Forest</p>
            </div>
          </div>
          <div class="item">
            <div class="hero-img">
              <img
                src="../../images/Project_showcase/ojasvi-project-showcase-03.jpg"
                alt=""
              />
            </div>
            <div class="desc ">
              <p class="carsl_cnt">Fruit Orchard</p>
            </div>
          </div>
          <div class="item">
            <div class="hero-img">
              <img
                src="../../images/Project_showcase/ojasvi-project-showcase-04.jpg"
                alt=""
              />
            </div>
            <div class="desc ">
              <p class="carsl_cnt">Club Street</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project_showcase;
