import React from "react";

const Project_Highlights = () => {
  return (
    <div className="project_highlights" id="highlights">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Project Highlights</h2>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div class="row g-0">
          <div class="col-lg-3 col-6 isActive">
            <div class="pd-list">
              <div class="pd-list-content">
                <div class="pdl-count count-1">10+</div>
                <p>
                  Acres
                  <br />
                  of land stretch
                </p>
              </div>
              <img
                src="../../images/Project_Highlights/bg-res-proj-detail-01.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="col-lg-3 col-6">
            <div class="pd-list">
              <div class="pd-list-content">
                <div class="pdl-count count-2">
                  30<span>+</span>
                </div>
                <p>
                  Amenities <br />
                  LifeDesigned® for you
                </p>
              </div>
              <img
                src="../../images/Project_Highlights/bg-res-proj-detail-02.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="col-lg-3 col-6">
            <div class="pd-list">
              <div class="pd-list-content">
                <div class="pdl-count count-3">03</div>
                <p>
                  High Rise
                  <br />
                  Towers
                </p>
              </div>
              <img
                src="../../images/Project_Highlights/bg-res-proj-detail-03.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="col-lg-3 col-6">
            <div class="pd-list">
              <div class="pd-list-content">
                <div class="pdl-count count-4">
                  80<span>%</span>
                </div>
                <p>
                  Open Spaces <br />
                  with Landscaped Amenities
                </p>
              </div>
              <img
                src="../../images/Project_Highlights/bg-res-proj-detail-04.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project_Highlights;
