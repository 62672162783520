import React from "react";

const Faq = () => {
  return (
    <div className="container mt-5">
      <div class="faqs-section section bg-grey">
        <div class="lg-container">
          <h2 class="text-center pb-4">FAQs</h2>
        </div>
        <div id="accordion" className="be-accordion">
          <div class="card accordion-item">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  class="accordion-button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is Birla Ojasvi RR Nagar?
                </button>
              </h5>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="accordion-body">
                Birla Ojasvi is a LifeDesigned® residential offering by Birla
                Estates. Sprawling across 10.23 acres in the vibrant locale of
                Rajarajeshwari Nagar, Bengaluru. Birla Ojasvi offers 1 BHK, 2
                BHK, 3 BHK and 4 BHK apartments to experience high rise living
                in Bengaluru. Thoughtfully designed and curated, Birla Ojasvi
                offers spacious, well-ventilated residences surrounded by
                landscaped greens that celebrates the limitless possibilities of
                youthful living.
              </div>
            </div>
          </div>
          <div class="card accordion-item">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button
                  class="accordion-button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  How many towers/blocks are part of Birla Ojasvi RR Nagar?
                </button>
              </h5>
            </div>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div class="card-body">
                Birla Ojasvi pulsates with vibrant energy, presenting a dynamic
                residential community that includes three soaring towers with
                30+ residential floors of apartments and one block of
                meticulously designed row houses. This diverse array of living
                options perfectly mirrors the brand's commitment to offering
                thoughtfully curated lifestyles, infusing the project with an
                energetic ambiance that resonates with the spirit of a vibrant
                and thriving community.
              </div>
            </div>
          </div>
          <div class="card accordion-item">
            <div class="card-header" id="headingThree">
              <h5 class="mb-0">
                <button
                  class="accordion-button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What are total no. of floors in each tower at Birla Ojasvi
                  Rajarajeshwari Nagar?
                </button>
              </h5>
            </div>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordion"
            >
              <div class="card-body">
                The total number of floors in each tower of Birla Ojasvi are as
                follows : Tower-1 (Signature Tower) at Birla Ojasvi RR Nagar has
                Basement + Ground + 33 Floors Tower-2 at Birla Ojasvi RR Nagar
                has Basement + Ground + 32 Floors Tower-3 at Birla Ojasvi RR
                Nagar has Basement + Ground + 31 Floors This distribution offers
                a variety of options for future residents with various
                assortments and views based on their preferences.
              </div>
            </div>
          </div>
          <div class="card accordion-item">
            <div class="card-header" id="headingFour">
              <h5 class="mb-0">
                <button
                  class="accordion-button"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Who are the architects and contractors of Birla Ojasvi
                  Rajarajeshwari Nagar?
                </button>
              </h5>
            </div>
            <div
              id="collapseFour"
              class="collapse"
              aria-labelledby="headingFour"
              data-parent="#accordion"
            >
              <div class="card-body">
                Birla Ojasvi is designed by the expertise of renowned
                architects. The project's concept architects are Broadway
                Malyan, a global design firm recognized for their innovative and
                cutting-edge designs. With a reputation for excellence on an
                international scale, Broadway Malyan brings a vision that aligns
                perfectly with the vibrancy, action, and youthfulness of Birla
                Ojasvi. Additionally, RSP India Pvt Ltd serves as the project
                architects, bringing a wealth of experience and a commitment to
                excellence that contributes to making Birla Ojasvi an
                architectural masterpiece in Rajarajeshwari Nagar, Bengaluru.
                Their credentials reflect a dedication to delivering projects
                that redefine living spaces with creativity and precision,
                enhancing the overall appeal of the Birla Ojasvi RR Nagar.
              </div>
            </div>
          </div>
          <div class="card accordion-item">
            <div class="card-header" id="headingFive">
              <h5 class="mb-0">
                <button
                  class="accordion-button"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  What are Clubhouse Amenities at Birla Ojasvi?
                </button>
              </h5>
            </div>
            <div
              id="collapseFive"
              class="collapse"
              aria-labelledby="headingFive"
              data-parent="#accordion"
            >
              <div class="card-body">
                Birla Ojasvi's clubhouse is the heart of recreation and
                wellness, offering diverse amenities. Residents at Birla Ojasvi
                Rajarajeshwari Nagar enjoy a multi-purpose hall, a top-notch
                gym, engaging VR games, a mini theater, and a library. Birla
                Ojasvi offers badminton and squash court, indoor games, and
                aerobics for sports enthusiasts. Steam & sauna facilities, along
                with a terrace party space, complete the vibrant lifestyle at
                Birla Ojasvi.
              </div>
            </div>
          </div>
          <div class="card accordion-item">
            <div class="card-header" id="headingsix">
              <h5 class="mb-0">
                <button
                  class="accordion-button"
                  data-toggle="collapse"
                  data-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  What are Podium Amenities at Birla Ojasvi RR Nagar?
                </button>
              </h5>
            </div>
            <div
              id="collapseSix"
              class="collapse"
              aria-labelledby="headingsix"
              data-parent="#accordion"
            >
              <div class="card-body">
                The podium at Birla Ojasvi is designed to be a leisure paradise,
                featuring a swimming pool, a kid's pool, barbeque pods, cultural
                lawn, poolside lawn, seating decks, expansive lawns, and
                dedicated play areas for children. This thoughtful integration
                of amenities on the podium enhances the social and recreational
                fabric of the community.
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default Faq;
